export const Anchor = () => import('../../components/Anchor.vue' /* webpackChunkName: "components/anchor" */).then(c => wrapFunctional(c.default || c))
export const AnimBottomToTop = () => import('../../components/AnimBottomToTop.vue' /* webpackChunkName: "components/anim-bottom-to-top" */).then(c => wrapFunctional(c.default || c))
export const AnimTopToBottom = () => import('../../components/AnimTopToBottom.vue' /* webpackChunkName: "components/anim-top-to-bottom" */).then(c => wrapFunctional(c.default || c))
export const ContentStrapi = () => import('../../components/ContentStrapi.vue' /* webpackChunkName: "components/content-strapi" */).then(c => wrapFunctional(c.default || c))
export const LegalNav = () => import('../../components/LegalNav.vue' /* webpackChunkName: "components/legal-nav" */).then(c => wrapFunctional(c.default || c))
export const MainNav = () => import('../../components/MainNav.vue' /* webpackChunkName: "components/main-nav" */).then(c => wrapFunctional(c.default || c))
export const MenuMobile = () => import('../../components/MenuMobile.vue' /* webpackChunkName: "components/menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/PageLoader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../components/Share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const SocialMedias = () => import('../../components/SocialMedias.vue' /* webpackChunkName: "components/social-medias" */).then(c => wrapFunctional(c.default || c))
export const Sticky = () => import('../../components/Sticky.vue' /* webpackChunkName: "components/sticky" */).then(c => wrapFunctional(c.default || c))
export const SubNav = () => import('../../components/SubNav.vue' /* webpackChunkName: "components/sub-nav" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const SvgArrowDown = () => import('../../components/svg/ArrowDown.vue' /* webpackChunkName: "components/svg-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const SvgArrowRight = () => import('../../components/svg/ArrowRight.vue' /* webpackChunkName: "components/svg-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const SvgBurger = () => import('../../components/svg/Burger.vue' /* webpackChunkName: "components/svg-burger" */).then(c => wrapFunctional(c.default || c))
export const SvgCanada = () => import('../../components/svg/Canada.vue' /* webpackChunkName: "components/svg-canada" */).then(c => wrapFunctional(c.default || c))
export const SvgCarouselNext = () => import('../../components/svg/CarouselNext.vue' /* webpackChunkName: "components/svg-carousel-next" */).then(c => wrapFunctional(c.default || c))
export const SvgCarouselPrev = () => import('../../components/svg/CarouselPrev.vue' /* webpackChunkName: "components/svg-carousel-prev" */).then(c => wrapFunctional(c.default || c))
export const SvgChat = () => import('../../components/svg/Chat.vue' /* webpackChunkName: "components/svg-chat" */).then(c => wrapFunctional(c.default || c))
export const SvgChevron = () => import('../../components/svg/Chevron.vue' /* webpackChunkName: "components/svg-chevron" */).then(c => wrapFunctional(c.default || c))
export const SvgClose = () => import('../../components/svg/Close.vue' /* webpackChunkName: "components/svg-close" */).then(c => wrapFunctional(c.default || c))
export const SvgComputer = () => import('../../components/svg/Computer.vue' /* webpackChunkName: "components/svg-computer" */).then(c => wrapFunctional(c.default || c))
export const SvgDownload = () => import('../../components/svg/Download.vue' /* webpackChunkName: "components/svg-download" */).then(c => wrapFunctional(c.default || c))
export const SvgExternal = () => import('../../components/svg/External.vue' /* webpackChunkName: "components/svg-external" */).then(c => wrapFunctional(c.default || c))
export const SvgFacebook = () => import('../../components/svg/Facebook.vue' /* webpackChunkName: "components/svg-facebook" */).then(c => wrapFunctional(c.default || c))
export const SvgFiligrane = () => import('../../components/svg/Filigrane.vue' /* webpackChunkName: "components/svg-filigrane" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoBeyond = () => import('../../components/svg/Ico-Beyond.vue' /* webpackChunkName: "components/svg-ico-beyond" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoClients = () => import('../../components/svg/Ico-Clients.vue' /* webpackChunkName: "components/svg-ico-clients" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoDifference = () => import('../../components/svg/Ico-Difference.vue' /* webpackChunkName: "components/svg-ico-difference" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoDifference2 = () => import('../../components/svg/Ico-Difference2.vue' /* webpackChunkName: "components/svg-ico-difference2" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoEngaged = () => import('../../components/svg/Ico-Engaged.vue' /* webpackChunkName: "components/svg-ico-engaged" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoExcellence = () => import('../../components/svg/Ico-Excellence.vue' /* webpackChunkName: "components/svg-ico-excellence" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoExperience = () => import('../../components/svg/Ico-Experience.vue' /* webpackChunkName: "components/svg-ico-experience" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoGroup = () => import('../../components/svg/Ico-Group.vue' /* webpackChunkName: "components/svg-ico-group" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoPartner = () => import('../../components/svg/Ico-Partner.vue' /* webpackChunkName: "components/svg-ico-partner" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoResponsabilities = () => import('../../components/svg/Ico-Responsabilities.vue' /* webpackChunkName: "components/svg-ico-responsabilities" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoSustainability = () => import('../../components/svg/Ico-Sustainability.vue' /* webpackChunkName: "components/svg-ico-sustainability" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoTeam = () => import('../../components/svg/Ico-Team.vue' /* webpackChunkName: "components/svg-ico-team" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoTechnology = () => import('../../components/svg/Ico-Technology.vue' /* webpackChunkName: "components/svg-ico-technology" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoBtnCar = () => import('../../components/svg/IcoBtnCar.vue' /* webpackChunkName: "components/svg-ico-btn-car" */).then(c => wrapFunctional(c.default || c))
export const SvgIcoBtnTruck = () => import('../../components/svg/IcoBtnTruck.vue' /* webpackChunkName: "components/svg-ico-btn-truck" */).then(c => wrapFunctional(c.default || c))
export const SvgLinkedin = () => import('../../components/svg/Linkedin.vue' /* webpackChunkName: "components/svg-linkedin" */).then(c => wrapFunctional(c.default || c))
export const SvgLogoThin = () => import('../../components/svg/LogoThin.vue' /* webpackChunkName: "components/svg-logo-thin" */).then(c => wrapFunctional(c.default || c))
export const SvgMailEmpty = () => import('../../components/svg/Mail-Empty.vue' /* webpackChunkName: "components/svg-mail-empty" */).then(c => wrapFunctional(c.default || c))
export const SvgMail = () => import('../../components/svg/Mail.vue' /* webpackChunkName: "components/svg-mail" */).then(c => wrapFunctional(c.default || c))
export const SvgNmedia = () => import('../../components/svg/Nmedia.vue' /* webpackChunkName: "components/svg-nmedia" */).then(c => wrapFunctional(c.default || c))
export const SvgPhone = () => import('../../components/svg/Phone.vue' /* webpackChunkName: "components/svg-phone" */).then(c => wrapFunctional(c.default || c))
export const SvgTwitter = () => import('../../components/svg/Twitter.vue' /* webpackChunkName: "components/svg-twitter" */).then(c => wrapFunctional(c.default || c))
export const SvgUap = () => import('../../components/svg/Uap.vue' /* webpackChunkName: "components/svg-uap" */).then(c => wrapFunctional(c.default || c))
export const SvgUapColor = () => import('../../components/svg/UapColor.vue' /* webpackChunkName: "components/svg-uap-color" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
